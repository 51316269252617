<template>
  <v-container class="offset-sm3">
    <v-list two-line subheader>
      <v-subheader>Account Setting</v-subheader>
      <v-list-item class="my-5" to="/accounts/payment">
        <v-list-item-action>
          <v-icon color="primary">credit_card</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="primary">Manage your Payment Accounts</v-btn>
          </v-list-item-title>
          <v-list-item-subtitle
            >Add / update your Credit Card information</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/accounts/payment_history">
        <v-list-item-action>
          <v-icon color="success">account_balance</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="success">View your payment history</v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>Account Transactions</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-row> <v-col cols="12" sm="6"> </v-col> </v-row>
    <v-row> <v-col cols="12" sm="6"> </v-col> </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isChef: 'isChef'
    })
  }
}
</script>
<style scoped>
.account_title {
  height: 50px;
}
</style>
